<!-- tinymce富文本编辑器组件 -->
<template>
  
  <div style="position: relative;">
    <editor v-model="content" :init="config" :disabled="disabled" style='resize="none"'></editor>

    <!-- <div class="tinymceDialog">
      <span>上传视频中....</span>
    </div> -->
   
  </div>
  
</template>

<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/code'
import 'tinymce/plugins/print'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/save'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/emoticons/js/emojis'
import axios from "axios";

export default {
  name: "TinymceEditor",
  components: {Editor},
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: String,
    init: Object,
    disabled: Boolean,
  },
  data() {
    return {
      centerDialogVisible:false,
      content: '',
      config: Object.assign({
        height: 300,
        branding: false,
        skin_url: '/tinymce/skins/ui/oxide',
        content_css: '/tinymce/skins/content/default/content.css',
        language_url: '/tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        plugins: 'code print preview fullscreen paste searchreplace save autosave link autolink image imagetools media table codesample lists advlist hr charmap emoticons anchor directionality pagebreak quickbars nonbreaking visualblocks visualchars wordcount',
        toolbar: 'fullscreen preview code | undo redo | forecolor backcolor | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | formatselect fontselect fontsizeselect | link image media emoticons charmap anchor pagebreak codesample | ltr rtl',
        toolbar_drawer: 'sliding',
				images_upload_handler: (blobInfo, success) => {
					var  formData;
					var file = blobInfo.blob();//转化为易于理解的file对象
					formData = new FormData();
					formData.append('file', file, file.name );
          // //console.log(formData)
					axios.post('/upload/uploadImage', formData,{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(function (response) {
						success(response.data.data);
						// //console.log("1",response.data.data)
					}).catch(function () {
						// //console.log("2",error);
					})
				},
        // images_upload_handler: (blobInfo, success) => {
        //   success('data:image/jpeg;base64,' + blobInfo.base64());
        // },
        file_picker_types: 'media',
        video_template_callback:data=>{
          return `<video controls="controls" style="max-width:100%;" src=${data.source} />`
        },
        // 自定义文件上传(这里使用把选择的文件转成blob演示)
				file_picker_callback: (callback, value, meta) => {
					let input = document.createElement('input');
					input.setAttribute('type', 'file');
          // 设定文件可选类型
					if (meta.filetype === 'image') {
						input.setAttribute('accept', 'image/*');
					} else if (meta.filetype === 'media') {
						input.setAttribute('accept', 'video/*');
					}
					input.onchange = () => {
						let file = input.files[0];
            var formData= new FormData();
            formData.append('file', file);
            // 弹出上传中提示框
            let tinymceMask=document.getElementById('tinymceMask')
            let tinymceMaskErrorId=document.getElementById('tinymceMaskError')
            let tinymceMaskError=document.getElementsByClassName('tinymceMaskError')
            tinymceMask.style.display="block"
							axios.post('/upload/uploadImage', formData,{
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }).then(function (response) {
                  if (response.data.code < 0) {
                    // //console.log("3",response.data)

                    tinymceMask.style.display="none"
                    tinymceMaskErrorId.style.display="block"
                    tinymceMaskError[0].innerHTML=response.data.msg
                    setTimeout(function () {
                      tinymceMaskErrorId.style.display="none"
                    }, 2000)

                  } else {
                    tinymceMask.style.display="none"
                    callback(response.data.data);
                    // //console.log("1",response.data.data)
                  }
                }).catch(function (error) {
                  tinymceMask.style.display="none"
                  // //console.log("2",error);
                })
							
						// reader.readAsArrayBuffer(file);
					}
          input.click();
					
				}
      }, this.init)
    }
  },
  mounted() {
    tinymce.init({});
  },
  watch: {
    content() {
      this.$emit('change', this.content);
    },
    value() {
      // //console.log(this.value)
      this.content = this.value;
    }
  }
}
</script>

<style>
body .tox-tinymce-aux {
  z-index: 19892000;
}
.tinymceDialog{
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
}
.tinymceDialog span{
  display: inline-block;
  padding: 20px 40px;
  font-size: 20px;
  margin: 60px auto;
  background-color: #fff;
  border-radius: 10px;
}
.tox-form::after {
                content: "视频格式mp4,图片格式gif/jpeg/png,文件大小50M以下";
                color:#666666;
                font-size: 11px;
        }
</style>